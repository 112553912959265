import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMedia } from "../../hooks";

// import "@stylesPages/Legal.scss";
import "../../styles/pages/Legal.scss";

// import { SEO, LegalTabs, Header, Footer } from "@components";
import { SEO, LegalTabs, Header, Footer } from "../../components";

export default function Legals({ navigate, params, location }) {
  const { t } = useTranslation("legals");
  const tabletLarge = useMedia("(max-width: 900px)");
  const [tab, setTab] = useState(null);
  const [mobile, setMobile] = useState(false);
  const tabs = [
    "terms",
    "execution",
    "politics",
    "AML",
    "conflicts",
    "divulgation",
    "KYC",
    "BOND",
  ];

  const handleTab = (select) => {
    navigate(`/legals/${select}`);
    if (mobile === true) setMobile(!mobile);
  };

  useEffect(() => {
    if (tabs.includes(params.label)) {
      setTab(params.label);
    } else {
      navigate("/legals/terms");
    }
  }, [params, params, navigate]);

  return (
    <div className="legal">
      <Header />
      <div className="legal__header">
        <h1 className="legal__header-title">{t("legals")}</h1>
        {tabletLarge ? (
          <button
            type="button"
            className="legal__select-option"
            data-select={mobile}
            onClick={() => setMobile(!mobile)}
          >
            <p className="legal__select-option--text">{tab}</p>
          </button>
        ) : null}
        <ul
          className={`legal__header-nav${
            mobile ? " legal__header-nav--mobile" : ""
          }`}
        >
          {tabs.map((item) => (
            <li key={item}>
              <button
                type="button"
                onClick={() => handleTab(item)}
                className={`legal__header-nav-item ${
                  tab === item ? " legal__header-nav-item--active" : ""
                }`}
              >
                {item === "terms" ? t("terms-title", { ns: "terms" }) : t(item)}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <section className="legal__body">
        {tab === "terms" ? (
          <LegalTabs
            title={t("terms-title", { ns: "terms" })}
            subtitles={[
              t("title-one", { ns: "terms" }),
              t("title-two", { ns: "terms" }),
              t("title-three", { ns: "terms" }),
              t("title-four", { ns: "terms" }),
              t("title-five", { ns: "terms" }),
              t("title-six", { ns: "terms" }),
              t("title-seven", { ns: "terms" }),
              t("title-eight", { ns: "terms" }),
              t("title-nine", { ns: "terms" }),
              t("title-ten", { ns: "terms" }),
              t("title-eleven", { ns: "terms" }),
              t("title-twelve", { ns: "terms" }),
              t("title-thirteen", { ns: "terms" }),
              t("title-fourteen", { ns: "terms" }),
              t("text-fifteen", { ns: "terms" }),
              t("title-sixteen", { ns: "terms" }),
              t("title-seventeen", { ns: "terms" }),
              t("title-eighteen", { ns: "terms" }),
              t("title-nineteen", { ns: "terms" }),
              t("title-twenty", { ns: "terms" }),
              t("title-twentyone", { ns: "terms" }),
              t("title-twentytwo", { ns: "terms" }),
              t("title-twentythree", { ns: "terms" }),
              t("title-twentyfour", { ns: "terms" }),
              t("title-twentyfive", { ns: "terms" }),
              t("title-twentysix", { ns: "terms" }),
            ]}
            texts={[
              t("text-one", { ns: "terms" }),
              t("text-two", { ns: "terms" }),
              t("text-three", { ns: "terms" }),
              t("text-four", { ns: "terms" }),
              t("text-five", { ns: "terms" }),
              t("text-six", { ns: "terms" }),
              t("text-seven", { ns: "terms" }),
              t("text-eight", { ns: "terms" }),
              t("text-nine", { ns: "terms" }),
              t("text-ten", { ns: "terms" }),
              t("text-eleven", { ns: "terms" }),
              t("text-twelve", { ns: "terms" }),
              t("text-thirteen", { ns: "terms" }),
              t("text-fourteen", { ns: "terms" }),
              t("text-fifteen", { ns: "terms" }),
              t("text-sixteen", { ns: "terms" }),
              t("text-seventeen", { ns: "terms" }),
              t("text-eighteen", { ns: "terms" }),
              t("text-nineteen", { ns: "terms" }),
              t("text-twenty", { ns: "terms" }),
              t("text-twentyone", { ns: "terms" }),
              t("text-twentytwo", { ns: "terms" }),
              t("text-twentythree", { ns: "terms" }),
              t("text-twentyfour", { ns: "terms" }),
              t("text-twentyfive", { ns: "terms" }),
              t("text-twentysix", { ns: "terms" }),
            ]}
          />
        ) : null}
        {tab === "execution" ? (
          <LegalTabs
            title={t("execution_t")}
            subtitles={[
              t("execution_sub_1"),
              t("execution_sub_2"),
              t("execution_sub_3"),
              t("execution_sub_4"),
              t("execution_sub_5"),
              t("execution_sub_6"),
              t("execution_sub_7"),
              t("execution_sub_8"),
              t("execution_sub_9"),
            ]}
            texts={[
              t("execution_txt_1"),
              t("execution_txt_2"),
              t("execution_txt_3"),
              t("execution_txt_4"),
              t("execution_txt_5"),
              t("execution_txt_6"),
              t("execution_txt_7"),
              t("execution_txt_8"),
              t("execution_txt_9"),
            ]}
          />
        ) : null}
        {tab === "politics" ? (
          <LegalTabs
            title={t("politics_t")}
            subtitles={[
              t("politics_sub_1"),
              t("politics_sub_2"),
              t("politics_sub_3"),
              t("politics_sub_4"),
              t("politics_sub_5"),
              t("politics_sub_6"),
            ]}
            texts={[
              t("politics_txt_1"),
              t("politics_txt_2"),
              t("politics_txt_3"),
              t("politics_txt_4"),
              t("politics_txt_5"),
              t("politics_txt_6"),
            ]}
            styles="politics"
          />
        ) : null}
        {tab === "AML" ? (
          <LegalTabs
            title={t("AML_t")}
            subtitles={[
              t("AML_sub_1"),
              t("AML_sub_2"),
              t("AML_sub_3"),
              t("AML_sub_4"),
              t("AML_sub_5"),
            ]}
            texts={[
              t("AML_txt_1"),
              t("AML_txt_2"),
              t("AML_txt_3"),
              t("AML_txt_4"),
              t("AML_txt_5"),
            ]}
          />
        ) : null}
        {tab === "conflicts" ? (
          <LegalTabs
            title={t("conflicts_t")}
            subtitles={[
              t("conflicts_sub_1"),
              t("conflicts_sub_2"),
              t("conflicts_sub_3"),
              t("conflicts_sub_4"),
              t("conflicts_sub_5"),
              t("conflicts_sub_6"),
              t("conflicts_sub_7"),
              t("conflicts_sub_8"),
              t("conflicts_sub_9"),
            ]}
            texts={[
              t("conflicts_txt_1"),
              t("conflicts_txt_2"),
              t("conflicts_txt_3"),
              t("conflicts_txt_4"),
              t("conflicts_txt_5"),
              t("conflicts_txt_6"),
              t("conflicts_txt_7"),
              t("conflicts_txt_8"),
              t("conflicts_txt_9"),
            ]}
          />
        ) : null}
        {tab === "divulgation" ? (
          <LegalTabs
            title={t("divulgation_t")}
            subtitles={[t("divulgation_sub_1")]}
            texts={[t("divulgation_txt_1")]}
          />
        ) : null}
        {tab === "KYC" ? (
          <LegalTabs
            title={t("KYC_t")}
            subtitles={[
              t("KYC_sub_1"),
              t("KYC_sub_2"),
              t("KYC_sub_3"),
              t("KYC_sub_4"),
              t("KYC_sub_5"),
              t("KYC_sub_6"),
            ]}
            texts={[
              t("KYC_txt_1"),
              t("KYC_txt_2"),
              t("KYC_txt_3"),
              t("KYC_txt_4"),
              t("KYC_txt_5"),
              t("KYC_txt_6"),
            ]}
            styles="kyc"
          />
        ) : null}
        {tab === "BOND" ? (
          <LegalTabs
            title={t("BOND_t")}
            subtitles={[
              t("BOND_sub_6"),
              t("BOND_sub_7"),
              t("BOND_sub_8"),
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]}
            texts={[
              t("BOND_txt_1"),
              t("BOND_txt_2"),
              t("BOND_txt_3"),
              t("BOND_txt_4"),
              t("BOND_txt_5"),
              t("BOND_txt_6"),
              t("BOND_txt_7"),
              t("BOND_txt_6"),
              t("BOND_txt_8"),
              t("BOND_txt_9"),
            ]}
            styles="kyc"
          />
        ) : null}
      </section>
      <Footer />
    </div>
  );
}

export const Head = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => node.ns === "legals");

  const { legals: titleSeo } = about
    ? JSON.parse(about.node.data)
    : {
        legals: "",
      };

  return <SEO title={titleSeo} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
